body {
  /* height: 100vh !important; */
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 13px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f7f9 !important; */
  color: #002841 !important;


}
.k-chart-tooltip-wrapper {
  margin-left: -9rem;
}

.k-grid .k-table-row.k-table-alt-row {

  background: rgba(0, 0, 0, 0.17) !important;

}
.fw-500{
  font-weight: 450 !important;
  color: #000000 !important;
}

h1,h2,h3,h4,h5,h6{
 
  font-weight: 550 !important;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
.k-table-row .k-grid-norecords{
  justify-content: left !important;
}
.bg-show{
 
 font-weight: 500 !important;
}
.k-grid .k-table-td.k-selected, .k-grid .k-table-row.k-selected  {
  background-color: #008cff59 !important;
border-radius: "10px"!important;
}
.k-sort-icon{
  color: white !important;
}
.printf{
  font-size: 13px !important;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.articles {
  font-size: small;
}

.followers {
  font-size: small;
}



.css-ahj2mt-MuiTypography-root {
  font-size: .9rem !important;
}

.app {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  /* flex-direction: column; */
  /* gap: 20px; */
  /* height: 100vh; */
  background-image: url('Images/2-min.png');
  background-size: cover;
  background-repeat: no-repeat;


}





/* input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #d27e01;
  border: 1px solid #d27801;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
} */

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 1rem;
  border-radius: 7%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 10%;
  min-width: 350px;
  margin-bottom: 10%;

}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: small;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  margin: 5px;
}

form {
  
  /* padding: 1rem; */
  border-radius: 1rem;
  outline: none;
  text-align: left;

}
.k-column-list {
  
  background-color: grey;
  
}
.k-grid .k-table-row.k-table-alt-row {
  background: rgba(0, 0, 0, 0.17);
}
.k-master-row.k-table-alt-row .k-grid-content-sticky {
  background: rgba(0, 0, 0, 0.15);
}
.k-grid-md .k-grid-header .k-grid-header-menu{
  color: #FFFFFF !important;
}
.k-grid-md .k-grid-header .k-grid-header-menu:hover{
  color: #ffcb1fc9 !important;
}
.k-actions .k-hstack .k-justify-content-stretch{
  background-color: grey;
}
/* input[type="button"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #006fba;
  border: 1px solid #006fba;
  color: #ffffff;
  padding: 10px 20px;
}

input[type="button"]:hover {
  background: #ffffff;
  color: #006fba;
} */

.k-input-inner::placeholder {
  color: #006fba !important;
}
.form-control-sm {
  
  padding: 0 !important;
  
}
/* ::-webkit-scrollbar {
  width: 16px;
  border: 5px solid white;

}

::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  background-clip: padding-box;
  border: 0.05em solid #eeeeee;
}

::-webkit-scrollbar-track {
  background-color: #bbbbbb;
}
/* Buttons */


.k-calendar .k-calendar-td:hover {
  border-color: #3f51b5 !important;
  color: white !important;
  background-color: #5665b9b9 !important;
}

/* ::-webkit-scrollbar {
  width: 15px;
  
}
::-webkit-scrollbar-button {
  background: #818181de
}
::-webkit-scrollbar-track-piece {
  background: #888;
  border-radius: 20px;  
}
::-webkit-scrollbar-thumb {
  background: #383838;
  border-radius: 20px; 
} */

.bg-fitek {
  background-color: #006fba;
}

.text-fitek {
  color: #006fba;
}

.tableheader {
  font-weight: 600;
  color: #006fba;
}

.tablediv{
  width: calc(100vw-100px) !important;
  background-color: f6f7f9;
}

.tablecard{
  
  margin-left: 0%;
  margin-right: 0%;
}

.form-control {
  display: flex !important;
}

.title {
  
  font-weight: 300;
  padding-bottom: 0%;
  margin-bottom: 0%;
  border-bottom: 1px solid #f4f4f2;

}

.subheader {

  font-weight: 600;
  color: #006fba;
  padding-left: 40px;

}

.reportheading {
  font-weight: 600 !important;
  color: #006fba;

}





.cursorp {
  cursor: pointer;
}


.sidebarstyle {


  height: 95vh;
  transition: 999999999999s;
  animation-name: linear;
}

.iconstyle {


  background-color: #ffffff !important;
  color: #f4f4f2;
}

.pro-sidebar:not(.collapsed) .popper-inner {
  display: none;
}

.pro-sidebar:not(.collapsed) .pro-sidebar-inner {
  width: fit-content;
}


.grid {
  position: relative;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}



.k-column-title {
  white-space: normal !important;
}

.toppings-list {
  list-style: none;
  padding: 0;
}

.toppings-list li {
  margin-bottom: 0.5rem;
}

.toppings-list-item {
  display: flex;
  justify-content: space-between;
}

.borderClass li:last-child {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}



.toppings-list-item label {
  vertical-align: text-bottom;
  margin-left: 0.2rem;
}

.pointer {
  cursor: pointer !important;
}

.k-card-title {
  color: #006fba !important;
}

.sticky-top-clss {
  position: fixed;
  top: 0;
  z-index: 5000;
  min-width: 100%;
  max-height: '30px';
}



.rightHeader {

  justify-content: right;
}
.centerHeader {

  justify-content: center;
}

.k-pager-wrap,
.k-pager {
  display: flex;
}



.tileLayout {

  grid-auto-rows: auto !important;
}

.k-tilelayout-item-header.k-card-header {
  display: none;
}



.k-animation-container {

   z-index: 9999 !important; 

}

.cardStyle {
  padding: 10px;
  min-width: 500px;
  border-radius: 5px;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  border: solid #d5d5d5 1px;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 10;
}
.ant-tabs-nav-list{
  
  z-index: 9999;
}
.\!k-overflow-auto{
  z-index: -5;
}
.cardTopItem {
  padding: 10px;
  min-width: 75px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  border: solid #d5d5d5 1px;
  margin: 5px;
  box-shadow: 1px 2px 5px #666666;
  background: #f4f4f2;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f4f4f2 0%, #f4f2ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f4f4f2 0%, #f4f2ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f4f4f2 0%, #f4f2ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f2', endColorstr='#f4f2ef', GradientType=0);
  /* IE6-9 */

}













/* .k-grid tr:nth-child(odd){
  background-color: #e7e6e6;
}
.k-grid tr:nth-child(even){
  background-color: #ffffff;
} */


/* .h5, h5 {
  font-size: 1rem !important;
} */

.kpicard{
  /* background-color: #FFFFFF; */
  margin: 0% !important;
  padding: 0% !important;
  
}

.dbcard{
  
  margin-right: 1px;
  margin-right: 1px;

}

.kpiheader{
  /* background-color: #FFFFFF !important; */
  font-size: 12px;
   
  text-align: start !important;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  
  text-overflow: ellipsis;
  max-width: fit-content;  
  
}

.kpiheader2{
  /* background-color: #FFFFFF !important; */
  font-size: 12px;
   
  text-align: start !important;
  font-weight: 500 !important;
  white-space: nowrap;
    
  
}

.kpibody{

  /* background-color: #FFFFFF; */
  
  font-size: 16px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: start !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
 
}













.k-list-item.k-selected {
  color: #fff !important;
  background-color: #008deb !important;
}
.kpibody:hover{
  text-overflow: clip;
}

.kpiheader:hover{
  text-overflow: clip;
}

  

.dbtab{
  min-height: 400px !important;
}



.tablecard{

  
  height: calc(100vh-500px) !important;



  
}

.accttext{

  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
      font-size: small !important;

}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.k-filter-menu-container{
  /*height:280px !important;*/
  max-height: 250px !important;
  max-width:400px !important;
 background-color: grey;
  }
  .k-checkbox-label
  {
   /* word-break: break-all !important;
   max-width: 200px !important; */
   white-space: normal;
  }

  .k-searchbox 
  {
    min-height: 40px !important;
  }

   .customCell{
    white-space: nowrap !important;
    text-decoration: underline;
    }

    

  .fontr{
    font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;

    
  }

.k-label{
  font-size: small !important;
}

#ddrMenu .dropdown-toggle::after {

  content: none !important;
  
  
}
.dropdown-menu {
  --bs-dropdown-padding-x: 0.2rem !important;
  --bs-dropdown-min-width: 15rem !important;

}







.k-grid .k-column-title {
  white-space: normal;
}

.k-grid .k-column-title:hover{
  color: #ffeb3bc9 !important;
}

.k-grid .k-grid-header .k-filterable:hover{
  color: #ffeb3bc9 !important;


}

.k-filterable .k-table-th .k-header:hover{
  
  color: #ffeb3bc9 !important;



}

.k-link:hover{
  color: inherit !important;


}
.k-grid .k-column-title:hover {
  color: #ffeb3bc9 !important;
}

.k-grid td {
  font-size: 13px !important;
    line-height: 1.5em !important;
 
  border-collapse: collapse;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  
  padding: 2px;

}

.k-grid .k-grouping-row p {
  min-width: 500px;
}

#tileGrid .k-grid-header {
  padding: 0 !important;
}

#tileGrid .k-grid-footer {
  padding: 0 !important;
}





.k-grid-header th, .k-grid-header tr{
  border: 0.25px solid #ccc !important;

}




.k-grid .k-grid-header th{
  background-color: #002841 !important;
  font-weight: 450 !important;
  color: #ffffff !important;
  font-size: 12px !important;
 
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 3px !important;

}

th.k-header.active .k-grid-column-menu {
  
  background-color: #006fba;
  border-radius: 10px !important;
}

/* Style for the standard rows */ 

.k-grid-content{
  background-color: transparent !important;
  
}
/* Style for the grouped header rows */
.k-grouping-row.k-alt {
    background-color: #e9e9e9 !important;/*  Slightly darker shade for grouped header rows */
}

.k-grid {
  
   background-color: transparent !important; 
}

.k-grid .k-master-row > td{
  border-left: 0 !important;
}




.k-grid .k-grouping-row  td{
  border-left: 0 !important;
}

.k-grid-footer td, .k-group-footer td, .k-grouping-row td, .k-grid-footer .k-table-td, .k-group-footer .k-table-td, .k-grouping-row .k-table-td{
  color:inherit !important;
}

/* Style for the detail rows (sub-rows) within each master row */


/* Style for the alternate detail rows (sub-rows) within each master row */

.ant-tabs-nav-operations-hidden{
  display: none !important;
}





.tabh{
  margin-top: 0px !important;
  
}

.acctext{
  
  white-space: nowrap;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  font-size: 13px !important;

}

.acctext:hover{
  text-overflow: clip;
  text-size-adjust: auto !important;

}


.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
 
  z-index: 100 !important;
  
  position: relative !important;
  
}

/* Enhanced Form Container */
.form-container-gwad {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #83838391;
  padding: 20px;
  border-radius: 12px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form General Styling */
.form-gwad {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-checkbox-row-gwad,
.form-row-gwad {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Labels */
.form-label-gwad {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  color: #4a4a4a;
}

/* Input Fields */
.form-input-gwad,
.combobox-gwad,
.multiselect-gwad {
  width: 100%;
  padding: 10px;
  border: 1px solid #494949;
  border-radius: 8px;
  font-size: 14px;
  
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-input-gwad:focus,
.combobox-gwad:focus,
.multiselect-gwad:focus {
  border-color: #0c71c3;
  box-shadow: 0 0 6px rgba(12, 113, 195, 0.4);
  outline: none;
}

/* Radio and Checkbox Styling */
.radio-label-gwad,
.checkbox-label-gwad {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  
  font-size: 14px;
  color: #4a4a4a;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.radio-label-gwad:hover,
.checkbox-label-gwad:hover {
  
  transform: translateY(-2px);
}

.radio-input-gwad,
.checkbox-input-gwad {
  margin-right: 8px;
}

/* Toggles and Switches */
.switch-gwad {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.switch-gwad:hover {
 
  transform: translateY(-2px);
}

/* Section Titles */
.section-title-gwad {
  font-size: 16px;
  font-weight: 600;
  color: #0c71c3;
  margin-bottom: 10px;
}

/* Error Messages */
.error-message-gwad {
  color: #ff4d4f;
  font-size: 13px;
  margin-top: 5px;
}

/* Footer Buttons */
button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
}

button.btn-outline-primary {
  background-color: #ffffff00;
  border: 1px solid #0c71c3;
  color: #0c71c3;
}

button.btn-outline-primary:hover {
  background-color: #0c71c3;
  
}

button.btn-primary {
  background-color: #0c71c3;
  border: none;
}

button.btn-primary:hover {
  background-color: #095a9b;
}



