
#sidebar .ProSidebar {
    background: #ffffff !important;
    transition: 900ms;
    
  }
  #sidebar .pro-sidebar-inner {
    background-color: #ffffff !important;

    transition: 970ms;
    color: #fdfdfd !important;
    
  }

  #sidebar .pro-sidebar-header {
    
    background-color: #ffffff !important;
    transition: 950ms;
    color: rgb(255, 255, 255) !important;
    
  }

#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  
  background: #000046;
  background: linear-gradient(to right, #203a43, #203a43);
  
  
}


/* #sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-item-content
  
  {
    background:linear-gradient(rgb(46, 46, 46),transparent);
  background-color: #ffffff;
  color: #000000;
  
} */
#sidebar
  
  
  .pro-inner-item
  .pro-inner-item
 
  
  {
    
  background-color: #ffffff;
  color: #595959;
  
} 
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  
  background-color: #ffffff;
  color: #000000;
}
.pro-sidebar .pro-item-content:hover{
  color: rgb(0, 0, 0);
  text-decoration:wavy;
  background-color: #5dc1e2;
}
.pro-sidebar .pro-item-content{
  color: rgb(0, 0, 0);
  text-decoration:wavy;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
  background-color: #5dc1e2;
}